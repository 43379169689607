<template>
<div>
    <div class="header">
        <!-- <h2>Select Region Of Interest</h2> -->
    </div>

    <hr />

    <input
        ref="input"
        type="file"
        name="image"
        accept="image/*"
        @change="setImage"
    />

    <div class="content">
    <section class="cropper-area">
        <div class="img-cropper">
        <vue-cropper
            ref="cropper"
            :src="imgSrc"
            preview=".preview"
            :viewMode="3"
            :zoomable="false"
            :autoCrop="false"
            @crop="handleCrop"
        />
        </div>

        <div class="actions">
        
        <a href="#" role="button" @click.prevent="reset"> Reset </a>

        </div>
 

        <div class="actions">
        <a href="#" role="button" @click.prevent="setROIDialogRoi(false)">
            Cancel
        </a>
        </div>
    </section>

    <section class="preview-area ml-4">
        <p>Preview</p>
        <div class="preview" /> 
        
        <v-row class="mt-1">
          <v-col cols="12">            
            <v-icon
              @click="setAllowAddingROI()"
              large
              color="green"
            >
              mdi-playlist-plus
            </v-icon>
          </v-col>
        </v-row>
    </section>
    </div>

    <!-- Overlay -->
    <v-overlay :value="overlay" z-index="10000">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
 
</div>
</template>
  
<script>
import { mapMutations, mapState } from "vuex";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
    name: 'DocumentManagementROIView',

    components: {
    VueCropper,
  },

  props: ["imgSrc","image", "labels"],

  data() {
    return {
      overlay: false,
      cropImg: "",
      data: null, 

      label: "",
    };
  },

  computed: {
    ...mapState({ auth: "auth", roi: "roi" }),
  },

  created() {
    //
  },

  watch: {
    "roi.imgSrc"(val) {
      this.$refs.cropper.replace(val);
      this.cropImg = "";
    },

    "roi.roi"(val) {
      this.$refs.cropper.setCropBoxData(val);
    },

    // imgSrc
    imgSrc(val) {
      this.$refs.cropper.replace(val);
      this.cropImg = "";
    },
  },

  methods: {
    ...mapMutations({
      setROIImgSrc: "roi/setImgSrc",
      setROIImgId: "roi/setImgId",
      setROIImage: "roi/setImage",
      setROIDialogRoi: "roi/setDialogRoi",
    }),

    // setAllowAddingROI
    setAllowAddingROI(){
      const roi = this.$refs.cropper.getCropBoxData();

      const imageData = this.$refs.cropper.cropper.getImageData();

      this.$store.commit("roi/setROI", roi);

      this.$store.commit("roi/setImageWidth", imageData.width);

      this.$store.commit("roi/setImageHeight", imageData.height);

      this.$store.commit("roi/setAllowAddingROI", true);
    },
    
    // handleCrop
    handleCrop() {
      const cropData = this.$refs.cropper.getData();
      console.log(cropData); 
    },

    // cropImage
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        let i = new FormData();
        i.append("picture", blob);
        console.log(i);
      });
    },

    // flipX
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },

    // flipY
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },

    // getCropBoxData
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },

    // getData
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },

    // move
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },

    // reset
    reset() {
      this.$refs.cropper.reset();
    },

    // rotate
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    // setCropBoxData
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },

    // setData
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },

    // setImage
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    // showFileChooser
    showFileChooser() {
      this.$refs.input.click();
    },

    // zoom
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },

    // toDataURL
    toDataURL(url) {
      fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );
    },
  },
};
</script>
 

<style lang="scss" scoped>
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}

input[type="file"] {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}
</style>
