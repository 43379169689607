<template>
  <div class="dashboard-inbox-details">
    <v-card class="pa-4 mt-2" elevation="1" rounded="lg">
      <hr class="mb-4" />
      <v-card class="pa-5 d-flex align-center justify-center secondary" width="100%" height="20px">
        <p class="body-1 text-center white--text mb-n1">
          {{ aiModelName }}
        </p>
      </v-card>
      <hr class="my-4" />
    </v-card>

    <v-row class="mt-5">
      <v-col cols="1">
        <v-card 
          @click="setCurrentImage(image)"
          v-for="(image, index) in images"
          :key="index"
          class="mt-4"
          max-width="150"
          max-height="150"
          flat
        >
          <div v-if="image.thumbnail_url != null">
            <v-img 
              alt="img"
              class="img pointer"
              lazy-src="https://via.placeholder.com/150"
              :src="image.thumbnail_url"
              contain
            />
          </div>

          <div v-else>
            <v-img 
              alt="img"
              class="img pointer"
              lazy-src="https://via.placeholder.com/150"
              :src="image.url"
              contain
            />
          </div> 
        </v-card>
      </v-col>

      <v-col cols="7"> 
        <div v-if="currentImage.url">        
          <!-- ROIView -->
          <ROIView 
            :imgSrc="setImgSrc()"
            :image="currentImage"
            :labels="labelsList"
          />
        </div> 

        <div v-else>
          <v-img 
            alt="img"
            class="img pointer"
            lazy-src="https://via.placeholder.com/150"
            src="https://via.placeholder.com/800"
            contain
          />
        </div> 
      </v-col>

      <v-col cols="4">
        <!-- dialogImage -->
        <v-dialog v-model="dialogImage" persistent max-width="500px">
          <template
            v-slot:activator="{ on, attrs }"
          >
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Add Image
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">Add New Image</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form :ref="formRef" lazy-validation>
                  <v-row>
                    <!-- Files -->
                    <v-col class="mt-n5" cols="12">
                      <v-file-input
                        v-model="files"
                        :rules="filesRules"
                        multiple
                        counter
                        show-size
                        placeholder="Click here to select files"
                        accept="image/png, image/jpeg"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="btn btn-primary" @click="closeImage()">
                Cancel
              </v-btn>
              <v-btn color="btn btn-primary" @click="saveImage()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Delete Image -->
        <v-btn
          @click="openDialogDeleteImage()"
          color="red"
          dark
          class="mb-2 ml-3"
        >
          Delete Image
        </v-btn>

        <!-- Train -->
        <v-btn
          @click="openDialogTrain()"
          color="primary"
          dark
          class="mb-2 ml-3"
        >
          Train
        </v-btn>

        <v-dialog v-model="dialogDeleteImage" max-width="550px">
          <v-card>
            <v-card-title class="text-h5 text-center">
              Are you sure you want to delete this image?
            </v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="btn btn-primary" @click="closeDeleteImage()">
                Cancel
              </v-btn>

              <v-btn
                color="btn btn-primary"
                @click="deleteImageConfirm()"
              >
              OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>        

        <v-dialog v-model="dialogTrain" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to train
              {{ aiModelName }}?
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="btn btn-primary"
                @click="closeTrainDialog()"
              >
                Cancel
              </v-btn>

              <v-btn
                color="btn btn-primary"
                @click="trainConfirm()"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-row v-if="allowAddingROI" class="mt-2">
        <!-- Set ROI -->
        <v-col cols="1" class="px-1 py-0">
         
        </v-col>

            <!-- Label Name -->
            <v-col cols="5" class="px-1 py-0">
              <v-text-field
                v-model="labelName"
                required
              >
                <template v-slot:label>
                  <p class="body-1">
                    Label Name <span class="red--text">*</span>
                  </p>
                </template>
              </v-text-field>
            </v-col>
            
            <!-- Label Type -->
            <v-col cols="4" class="px-1 py-0">
              <v-select
                v-model="labelType" 
                  :items="types"                 
                required
              >
                <template v-slot:label>
                  <p class="body-1">
                    Label Type <span class="red--text">*</span>
                  </p>
                </template>
              </v-select>
            </v-col>

            <!-- Delete -->
            <v-col cols="2" class="px-1 py-0 mt-4">
              <v-icon @click="addROI()"
                color="green" 
              >
                mdi-content-save
              </v-icon>
            </v-col>
        </v-row>

        <v-row v-for="(roi, index) in currentImage.rois"
               :key="index"
               class="mt-4"
        >
        <!-- Set ROI -->
        <v-col cols="1" class="px-1 py-0">
          <v-icon             
            color="green"
            class="mb-n12" @click="setROI(roi)"
          >
            mdi-eye
          </v-icon>
        </v-col>

            <!-- Label Name -->
            <v-col cols="5" class="px-1 py-0">
              <v-text-field
                v-model="roi.label_name"
                required
              >
                <template v-slot:label>
                  <p class="body-1">
                    Label Name <span class="red--text">*</span>
                  </p>
                </template>
              </v-text-field>
            </v-col>
           
            <!-- Label Type -->
            <v-col cols="4" class="px-1 py-0">
              <v-select
                v-model="roi.label_type" 
                 :items="types"                 
                required
              >
                <template v-slot:label>
                  <p class="body-1">
                    Label Type <span class="red--text">*</span>
                  </p>
                </template>
              </v-select>
            </v-col>

            <!-- Delete -->
            <v-col cols="2" class="px-1 py-0">
              <v-layout>
                <v-icon
                  @click="editROI(roi)"
                  class="mb-n12"
                  color="green" 
                >
                  mdi-content-save
                </v-icon>
  
                <v-icon
                  @click="deleteROI(roi)"
                  class="mb-n12"
                  color="red" 
                >
                  mdi-delete
                </v-icon>
              </v-layout>
            </v-col>
        </v-row>
        
        <v-alert v-if="currentImage && currentImage.rois && currentImage.rois.length === 0"
          class="mt-4"
          color="red"
          dark
        >
          There are no labels
        </v-alert>
      </v-col>
    </v-row>        

    <!-- Overlay -->
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import ROIView from "@/components/elements/dashboard/ai-models/view/ROIView";

export default {
  name: 'DocumentManagementAiModelView',

  components: {
    ROIView,
  },

  data() {
    return {
      overlay: false,
      aiModelId: 0,
      aiModel: {},
      currentImage: {},
      images: [],
      redirect: "",

      types: [
        "Numbers Only",
        "Name Pattern",
        "Text Only",
        "Sa Id Number",
      ],

      labelName: "",
      labelType: "",

      dialogImage: false,
      dialogDeleteImage: false,
      dialogTrain: false,

      // files
      files: null,
      filesRules: [(v) => !!v || "Required"],

      // formRef
      formRef: `formRef-${Math.random().toString(36).substring(7)}`,
    };
  },

  computed: {
    aiModelName() {
      if ("name" in this.aiModel) {
        return this.aiModel.name;
      }
      return "";
    },

    labelsList() {
      if(this.aiModel && "key_value_pairs_object" in this.aiModel){
        return Object.values(this.aiModel.key_value_pairs_object).map(values => values[0]);
      }

      return [];
    },

    allowAddingROI(){
      return this.$store.getters['roi/getAllowAddingROI'];
    }
  },

  created() {
    this.$store.commit("roi/resetState");

    const id = parseInt(this.$route.params.id);
    if (typeof id === "number" && !isNaN(id)) {
      this.aiModelId = id;
      this.getAiModel(id);
    } else {
      this.$router.push({ name: "DashboardAiModels" });
    }
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
      resetAiModelState: "aiModel/resetState",

      setDialogAiModel: "aiModel/setDialogAiModel",
      setTotalTime: "aiModel/setTotalTime",
      setAiModelId: "aiModel/setAiModelId",
      setTimeLeft: "aiModel/setTimeLeft",
      setTime: "aiModel/setTime",

      setCountDown: "aiModel/setCountDown",
    }),

    async getAiModel(id) {
      try {
        this.overlay = true; 
        const res = await this.axios.get(`ai-models/advanced/${id}`);
        console.log(res);

        if (res.status == 200) {
          const data = res.data;

          if (Object.keys(data.key_value_pairs_object).length > 0) {
            this.$router.push({ name: "DashboardAiModels" });
          }

          this.aiModel = data;

          if ("images" in data) {
            this.images = data.images;

            this.images.forEach(image => {              
              if (image.rois) {
                image.rois.forEach(roi => {
                  roi.label_type = this.convertToTitleCase(roi.label_type);
                });
              }
            });

            if (this.images.length > 0) {
              this.setCurrentImage(this.images[0]);
              this.$store.commit("roi/setImgId", this.currentImage.id);
            }
          }
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        this.$router.push({ name: "DashboardAiModels" });

        if (error.response.status == 401) {
          this.resetAuthState();
          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    setCurrentImage(image) {
      this.currentImage = image;

      this.$store.commit("roi/setROI", {});

      this.$store.commit("roi/setAllowAddingROI", false);
    },

    setImgSrc(){
      if (this.currentImage && "base64" in this.currentImage) {
        this.$store.commit("roi/setImgId", this.currentImage.id);

        var base64 = this.currentImage.base64;
        var fileType = this.currentImage.name.split(".").pop();

        // addDataUrl
        return this.addDataUrl(base64, fileType);
    }},

    // addDataUrl
    addDataUrl(base64, fileType) {
      return "data:image/" + fileType + ";base64," + base64;
    },

    setROI(roi){
      // setROI
      this.$store.commit("roi/setROI", {
        left: roi.left,
        top: roi.top,
        width: roi.width,
        height: roi.height
      });
    },

    addROI(){
      this.createApiROI();
    },

    editROI(roi){
      this.updateApiROI(roi);
    },

    deleteROI(roi){
      this.deleteApiROI(roi);
    },

    convertToUnderscoreFormat(str) {
      return str.toLowerCase().replace(/\s+/g, '_');
    },
    
    convertToTitleCase(str) {
      return str.split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
    },

    // createApiROI
    async createApiROI() {
      this.overlay = true;

      const roi = this.$store.getters['roi/getROI'];

      const res = await this.$helpers.createApiData(
        "rois",
        {
          image_id: this.$store.getters['roi/getImgId'],
          label_name: this.labelName,
          label_type: this.convertToUnderscoreFormat(this.labelType),          
          left: roi.left,
          top: roi.top,
          width: roi.width,
          height: roi.height,
          image_width: this.$store.getters['roi/getImageWidth'],
          image_height: this.$store.getters['roi/getImageHeight']
        },
        "ROI Created Successfully!"
      );

      if (res.status == 200) {
        this.aiModel.images.forEach((image) => {
          if (image.id === this.$store.getters['roi/getImgId']) {
            let data = res.data
            data.label_type = this.convertToTitleCase(data.label_type);

            image.rois.push(data);
          }
        });

        // this.currentImage.rois.push(res.data);
        
        this.$store.commit("roi/setAllowAddingROI", false);

        this.labelName = "";
        this.labelType = "";
      }

      this.overlay = false;
    },

    // updateApiROI
    async updateApiROI(roi) {
      this.overlay = true;

      const res = await this.$helpers.updateApiData(
        `rois/${roi.id}`,
        {
          id: roi.id,
          image_id: this.$store.getters['roi/getImgId'],
          label_name: roi.label_name,
          label_type: roi.label_type,          
          left: roi.left,
          top: roi.top,
          width: roi.width,
          height: roi.height,
          image_width: this.$store.getters['roi/getImageWidth'],
          image_height: this.$store.getters['roi/getImageHeight']
        },
        "ROI Updated Successfully!"
      );

      if (res.status == 200) {
        this.aiModel.images.forEach((image) => {
          if (image.id === this.$store.getters['roi/getImgId']) {
            const imageRoiIndex = image.rois.findIndex(r => r.id === roi.id);
            if (imageRoiIndex !== -1) {
              let data = res.data
              data.label_type = this.convertToTitleCase(data.label_type);

              Object.assign(image.rois[imageRoiIndex], data);
            }
          }
        });

        const currentImageRoiIndex = this.currentImage.rois.findIndex(r => r.id === roi.id);
        if (currentImageRoiIndex !== -1) {
          let data = res.data
          data.label_type = this.convertToTitleCase(data.label_type);  

          Object.assign(this.currentImage.rois[currentImageRoiIndex], data);
        }
      }

      this.overlay = false;
    },

    // deleteApiROI
    async deleteApiROI(roi) {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `rois/${roi.id}`,
        "ROI Deleted Successfully!"
      );

      if (res.status == 200) {
        this.aiModel.images.forEach((image) => {
          if (image.id === this.$store.getters['roi/getImgId']) {
            const imageRoiIndex = image.rois.findIndex(r => r.id === roi.id);
            if (imageRoiIndex !== -1) {
              image.rois.splice(imageRoiIndex, 1);
            }
          }
        });

        const currentImageRoiIndex = this.currentImage.rois.findIndex(r => r.id === roi.id);
        if (currentImageRoiIndex !== -1) {
          this.currentImage.rois.splice(currentImageRoiIndex, 1);
        }
      }

      this.overlay = false;
    },  

    // closeImage
    closeImage() {
      this.dialogImage = false;
    },

    // saveImage
    saveImage() {
      this.$refs[this.formRef].validate();

      if (this.$refs[this.formRef].validate()) {
        this.addImageToAiModel();
      }
    },

    // openDialogDeleteImage
    openDialogDeleteImage() {
      this.dialogDeleteImage = true;
    },

    // closeDeleteImage
    closeDeleteImage() {
      this.dialogDeleteImage = false;
    },

    // deleteImageConfirm
    deleteImageConfirm() {
      this.deleteImage();
    },

    // addImageToAiModel
    async addImageToAiModel() {
      try {
        this.overlay = true;

        let formData = new FormData();

        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];

          formData.append("files[" + i + "]", file);
        }

        let res = await this.axios.post(
          `ai-models/add-image-to-ai-model/${this.aiModelId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status == 200) {
          this.getLastAiModelImage();
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // getLastAiModelImage
    async getLastAiModelImage() {
      try {
        this.overlay = true;

        const res = await this.axios.get(`ai-models/advanced/get-last-image/${this.aiModelId}`);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Added Image Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          const data = res.data;

          // this.images.push(data);
          this.aiModel.images.push(data);

          this.files = null;

          this.dialogImage = false;
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // deleteImage
    async deleteImage() {
      this.overlay = true;

      const targetId = this.$store.getters['roi/getImgId'];

      const res = await this.$helpers.deleteApiData(
        `images/${targetId}`,
        "Image Deleted Successfully!"
      );

      if (res.status == 200) {
        this.aiModel.images = this.aiModel.images.filter(image => image.id !== targetId);
        
        this.images= this.aiModel.images;

        this.currentImage = {};

        if (this.images.length > 0) {
          this.setCurrentImage(this.images[0]);
          this.$store.commit("roi/setImgId", this.currentImage.id);
        }

        this.dialogDeleteImage = false;
      }

      this.overlay = false;
    }, 

    // openDialogTrain
    openDialogTrain() {
      this.dialogTrain = true;
    },

    // closeTrainDialog
    closeTrainDialog() {
      this.dialogTrain = false;
    },

    // trainConfirm
    trainConfirm() {
      this.trainApiAiModel();
    },

    // trainApiAiModel
    async trainApiAiModel() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `/ai-models/advanced/train-ai-model/${this.aiModelId}`,
        {},
        "Started Train Successfully!"
      );

      if (res.status == 200) {
        // setCountDown
        this.setCountDown(15 * 60);

        // setTimeLeft
        this.setTimeLeft(15 * 60 * 1000);

        // setDialogAiModel
        this.setDialogAiModel(true);

        // setAiModelId
        this.setAiModelId(this.aiModelId);

        this.dialogTrain = false;
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  // Styles can go here
</style>
