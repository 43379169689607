<template>
    <div class="background">
        <AiModelView />
    </div>
</template>

<script>
import AiModelView from "@/components/elements/dashboard/ai-models/view/AiModelView";

export default {
    name: 'DocumentManagementAiModelView',

    components: {
        AiModelView,
    },

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
//
</style>